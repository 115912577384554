<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";

export default {
  name: "WebSettings",
  extends: Base,
  data() {
    return {
      Name: "WebSettings",
      contact: {},
      address: {},
      socialAccount: {},
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
    this.moduleRedirect(false);
  },
  methods: {
    submitContact() {
      this.contact.type = "updateContact";
      BOGen.apirest(
        "/" + this.Name,
        this.contact,
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".contact-info");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".contact-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitAddress() {
      this.address.type = "updateAddress";
      BOGen.apirest(
        "/" + this.Name,
        this.address,
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info-address");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info-address");
            this.refreshData();
          }
        },
        "POST"
      );
    },

    submitSocialAccount() {
      this.socialAccount.type = "updateSocialAccount";
      BOGen.apirest(
        "/" + this.Name,
        this.socialAccount,
        (err, resp) => {
          if (resp.error)
            return Gen.info(
              resp.message,
              resp.style,
              3000,
              ".info-social-account"
            );
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info-social-account");
            this.refreshData();
          }
        },
        "POST"
      );
    },

    submitEmail() {
      this.contact.type = "updateEmail";
      
      if($("#c_telephone").text()){
        return
      }
      
      BOGen.apirest(
        "/" + this.Name,
        this.contact,
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info-email");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info-email");
            this.refreshData();
          }
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "contact.telephone.as_val"(v){
      let regex = /^\(\d{3}\)\s\d+/g
      if (v.length > 0) {
        if(v.match(regex) == null){
          $("[error='c_telephone']").html("<span class='error' id='c_telephone'>" + 'Input Tidak sesuai' + "</span>")
        }
        else{
          $("[error='c_telephone']").html('')
        }
      }

    },
    "filter.level"() {
      this.search();
    },
    "filter.search"(v) {
      this.search();
    },
  },
};
</script>
<style type="text/css">
.image-preview {
  background-color: #08affb12;
  padding: 3px;
}
</style>
<template>
  <div class="container-fluid">
    <PageTitle :no-add-item="true"></PageTitle>

    <!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <!-- Content Setting -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row gutter-10">
                <div class="col-sm-5">
                  <h5 class="card-title">Daftar {{ ObjectName }}</h5>
                </div>
              </div>
            </VForm>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Judul
                  </th>
                  <th>Konten</th>
                  <th
                    class="text-center"
                    width="120px"
                    v-if="moduleRole('Edit')"
                  >
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.as_name || "-" }}</td>
                  <td>{{ v.as_val || "-" }}</td>
                  <td class="btn-action text-center" v-if="moduleRole('Edit')">
                    <router-link
                      v-if="page.moduleRole('Edit')"
                      class="icon_action"
                      :to="{ name: Name, params: { id: v.id } }"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></router-link>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h3 class="tc">{{ NotFound }}</h3>
                  </td>
                </tr>
                <tr v-if="data.data === false">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- / Content Setting -->
      <!-- Address -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Alamat</h5>
          </div>
          <div class="card-body">
            <div class="info-address"></div>
            <VForm @resp="submitAddress" method="post">
              <div class="row">
                <div class="col-md-6">
                  <BoField name="googleMap" mandatory :label="'Google Map'">
                    <textarea
                      class="form-control"
                      minlength="10"
                      rows="5"
                      name="googleMap"
                      required=""
                      v-model="address.googleMap.as_val"
                      placeholder="e.g. https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4593220339934!2d106.73406041413872!3d-6.3344948637410825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ef849ea634f3%3A0x1f496b3b38e0f0d4!2sSekolah%20Dasar%20Islam%20Al Hidayah!5e0!3m2!1sid!2sid!4v1615594075838!5m2!1sid!2sid"
                    ></textarea>
                  </BoField>
                  <BoField name="address" mandatory :label="'Alamat'">
                    <textarea
                      class="form-control"
                      minlength="10"
                      rows="5"
                      name="address"
                      required=""
                      v-model="address.address.as_val"
                      placeholder="e.g. Jl. Anggur V, Pamulang Bar., Kec. Pamulang, Kota Tangerang Selatan, Banten 154178"
                    ></textarea>
                  </BoField>
                  <div class="row">
                    <div class="col-md-12"></div>
                    <div class="col-md-12"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fluid-framer">
                    <iframe
                      :src="address.googleMap.as_val"
                      height="360"
                      width="480"
                      class="contact-map"
                      allowfullscreen="true"
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading"
                >
                  Perbarui<i
                    class="icon-arrow-right14 position-right"
                  ></i>
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- / Address -->
      <!-- Social Account -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Akun Sosial Media</h5>
          </div>
          <div class="card-body">
            <div class="info-social-account"></div>
            <VForm @resp="submitSocialAccount" method="post">
              <div class="row">
                <div class="col-md-6">
                  <div class="card border">
                    <div class="card-body">
                      <BoField
                        name="sc_facebook"
                        mandatory
                        v-model="socialAccount.facebook.as_val.url"
                        :label="'Link Facebook'"
                        :attr="{placeholder: 'e.g. https://web.facebook.com/iqbalsdialhidayah'}"
                        required=""
                      >
                      </BoField>
                      <BoField name="sc_facebook_is_active" class="text-left">
                        <radio
                          v-model="socialAccount.facebook.as_val.active"
                          option="Y"
                          :attr="validation('ap_is_active')"
                          >Active</radio
                        >
                        <radio
                          v-model="socialAccount.facebook.as_val.active"
                          option="N"
                          >Inactive</radio
                        >
                      </BoField>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card border">
                    <div class="card-body">
                      <BoField
                        name="sc_instagram"
                        mandatory
                        v-model="socialAccount.instagram.as_val.url"
                        :label="'Link Instagram'"
                        :attr="{placeholder: 'e.g. https://www.instagram.com/sdialhidayah'}"
                        required=""
                      >
                      </BoField>
                      <BoField name="sc_instagram_is_active" class="text-left">
                        <radio
                          v-model="socialAccount.instagram.as_val.active"
                          option="Y"
                          :attr="validation('ap_is_active')"
                          >Active</radio
                        >
                        <radio
                          v-model="socialAccount.instagram.as_val.active"
                          option="N"
                          >Inactive</radio
                        >
                      </BoField>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card border">
                    <div class="card-body">
                      <BoField
                        name="sc_twitter"
                        mandatory
                        v-model="socialAccount.twitter.as_val.url"
                        :label="'Link Twitter'"
                        :attr="{placeholder: 'e.g. http://twitter.com/sdalhidayah/'}"
                        required=""
                      >
                      </BoField>
                      <BoField name="sc_twitter_is_active" class="text-left">
                        <radio
                          v-model="socialAccount.twitter.as_val.active"
                          option="Y"
                          :attr="validation('ap_is_active')"
                          >Active</radio
                        >
                        <radio
                          v-model="socialAccount.twitter.as_val.active"
                          option="N"
                          >Inactive</radio
                        >
                      </BoField>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading"
                >
                  Perbarui<i
                    class="icon-arrow-right14 position-right"
                  ></i>
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- / Social Account -->
      <!-- Email, Phone, Telephone  -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Kontak</h5>
          </div>
          <div class="card-body">
            <div class="info-email"></div>
            <VForm @resp="submitEmail" method="post">
              <div class="row">
                <div class="col-md-6">
                  <BoField
                    name="c_email"
                    mandatory
                    v-model="contact.email.as_val"
                    keyFilter="emailKey"
                    :label="'Alamat Email'"
                    :attr="{
                      type:'email',
                      placeholder: 'e.g. info.sdialhidayah@gmail.com'
                    }"
                    required=""
                  >
                  </BoField>
                </div>
                <div class="col-md-6">
                  <BoField
                    name="c_telephone"
                    v-model="contact.telephone.as_val"
                    mandatory
                    keyFilter="telephoneKey"
                    :label="'No Telephone'"
                    :attr="{
                      placeholder: 'e.g. (021) 7415311'
                    }"
                    required=""
                  >
                  </BoField>
                </div>
                <div class="col-md-6">
                  <BoField
                    name="c_phone"
                    v-model="contact.phone.as_val"
                    mandatory
                    keyFilter="mobileKey"
                    :label="'No Handphone'"
                    :attr="{
                      type: 'tel', maxlength: '13', 
                      placeholder: 'e.g. 62217415311'
                    }"
                    required=""
                  >
                  </BoField>
                </div>
              </div>
              <div class="mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading"
                >
                  Perbarui<i
                    class="icon-arrow-right14 position-right"
                  ></i>
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <!-- / Email, Phone, Telephone -->
    </div>

    <!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
    <div class="card" v-if="$route.params.id">
      <VForm @resp="submitForm" method="post">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-8">
              <h5 class="card-title">
                {{ (row.id ? "Ubah" : "Tambah") + " " + ObjectName }}
              </h5>
            </div>
          </div>
          <div class="info"></div>
          <div class="row">
            <div class="col-sm-6">
              <BoField 
                v-bind="validation('as_name')" 
                name="as_name" v-model="row.as_name" 
                :label="'Judul'" 
                :attr="{
                  placeholder: 'e.g Pagination Berita & Artikel',
                  readonly: 'readonly'
                }"
              ></BoField>
            </div>
            <div class="col-sm-6">
              <BoField
                name="as_val"
                v-if="$route.params.id == 21"
                :attr="{ type: 'email' }"
                v-model="row.as_val"
              ></BoField>
              <BoField
                name="as_val"
                v-else
                v-model="row.as_val"
                v-bind="validation('as_val')"
                :label="'Deskripsi'"
                :attr="{ type: 'number', placeholder: 'e.g 3'}"
              ></BoField>
            </div>
            <div class="col-12">
              <div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
                <button
                  type="submit"
                  class="fcbtn btn btn-info btn-outline btn-rounded btn-loading"
                >
                  Ubah
                </button>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>
